<template>
  <AdminTemplate v-loading="loadingPage">
    <el-row
      type="flex"
      v-if="room != null && typeof room.players != 'undefined'"
    >
      <el-col :span="21">
        <div class="is-flex ai-center">
          <v-qr
            :text="url + 'join-room/' + room.code"
            qrcodeColor="#000000ff"
            backgroundColor="#ffffffff"
            :size="245"
            :margin="10"
            :ratio="1"
            level="M"
          />
          <div class="mg-l-5">
            <h2><span class="text-grey">ชื่อห้อง:</span> {{ room.name }}</h2>
            <h2><span class="text-grey">Room ID:</span> {{ room.code }}</h2>
          </div>
        </div>
      </el-col>
      <el-col :span="3" class="is-flex ai-center js-end">
        <div>
          <div
            style="font-size: 104px; line-height: 1"
            class="text-primary text-center"
          >
            {{ room.players.length }}
          </div>
          <h2 class="mg-0">ผู้เข้าร่วม</h2>
        </div>
      </el-col>
    </el-row>
    <el-row
      class="mg-t-5"
      v-if="room != null && typeof room.players != 'undefined'"
    >
      <el-col
        :span="20"
        :offset="2"
        v-if="room.players.length == 0"
        class="mg-y-3"
      >
        <el-alert type="warning" :center="true" :closable="false">
          <h1><i class="el-icon-loading"></i> รอผู้เข้าทดสอบ...</h1>
        </el-alert>
      </el-col>
      <el-col
        v-else
        :span="6"
        v-for="(item, index) in room.players"
        :key="index"
        class="pd-5 animate__animated animate__bounce"
      >
        <div class="button-user text-dark text-center">
          {{ item.name }}
          <i class="fas fa-times-circle" @click="kickUser(item)"></i>
        </div>
      </el-col>
    </el-row>
    <el-row
      class="mg-t-5"
      v-if="room != null && typeof room.players != 'undefined'"
    >
      <el-col :span="8" :offset="8">
        <el-button
          type="warning"
          class="w-100 font-28 mg-b-4"
          @click="dialogVisible = true"
          >ความรู้เบื้องต้น</el-button
        >
      </el-col>
      <el-col
        :span="8"
        :offset="8"
        v-if="typeof room.players.length != 'undefined'"
      >
        <el-button
          :disabled="room.players.length == 0"
          type="primary"
          class="w-100 font-28"
          @click="startTest()"
          >เริ่มทำข้อสอบ</el-button
        >
      </el-col>
    </el-row>
    <el-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="65%"
      top="4vh"
    >
      <el-carousel
        :autoplay="false"
        :loop="false"
        trigger="click"
        indicator-position="outside"
        height="70vh"
      >
        <el-carousel-item>
          <img src="@/assets/exam/01_Start/Slide1.jpg" alt="" class="mw-100" />
        </el-carousel-item>
        <el-carousel-item>
          <video preload width="100%" controls>
            <source src="@/assets/exam/video/EXAC_intro.mp4" type="video/mp4" />
          </video>
        </el-carousel-item>
        <el-carousel-item>
          <img src="@/assets/exam/01_Start/Slide3.jpg" alt="" class="mw-100" />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </AdminTemplate>
</template>
<script>
import { HTTP } from "@/service/axios";
import AdminTemplate from "@/template/VoteAdminTemplate";
import vueQr from "vue-qr";
export default {
  components: {
    AdminTemplate,
    vueQr
  },
  computed: {
    room() {
      return this.$store.state.room;
    },
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    this.getRoom();
    this.intervalationation = setInterval(() => {
      this.getRoom();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalationation);
  },
  methods: {
    kickUser(data) {
      this.$confirm(`ต้องการลบ ${data.name} ออกจากห้อง หรือไม่?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then( () =>
        {
          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.delete(`player/${data.id}`).then(res => {
            if (res.data.success) {
              this.$message({
                type: "success",
                message: "ลบสำเร็จ"
              });
              this.getRoom();
            }
          });
        })
        .catch(e => {
          console.log(`player/${data.id} error`, e);
        });
    },
    startTest() {
      let obj = {
        roomId: this.room.roomId,
        qId: 1,
        attId: 1,
        total: false,
        state: "description"
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`state`, obj).then(async res => {
        await this.getState();
        if (res.data.success) {
          if (this.step != null) {
            this.$router.push("/slide");
          }
        }
      });
    }
  },
  data() {
    return {
      dialogVisible: true,
      url: process.env.VUE_APP_URL,
      intervalation: null,
      roomDetail: null
    };
  }
};
</script>